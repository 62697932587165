import React, { useState } from "react";
import { FiX } from "react-icons/fi";
import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";

import { HeaderLink } from "./HeaderLink";
import "./Header.css";
import { isHomeSite } from "lib/legacy";
import DropdownMenus from "../OtherDropdown/OtherDropdown";
import ExternalLink from "components/ExternalLink/ExternalLink";
import logoImg from "img/logo_OmniDex.png";

type Props = {
  small?: boolean;
  clickCloseIcon?: () => void;
  openSettings?: () => void;
  redirectPopupTimestamp: number;
  showRedirectModal: (to: string) => void;
};

export function AppHeaderLinks({
  small,
  openSettings,
  clickCloseIcon,
  redirectPopupTimestamp,
  showRedirectModal,
}: Props) {

  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const handleSubMenuChange = (parentItem) => {
    setActiveSubMenu(parentItem);
  };

  return (
    <div className="App-header-links">
      {small && (
        <div className="App-header-links-header">
          <Link className="App-header-link-main" to="/">
            <img src={logoImg} alt="GMX Logo" />
          </Link>
          <div
            className="App-header-menu-icon-block mobile-cross-menu"
            onClick={() => clickCloseIcon && clickCloseIcon()}
          >
            <FiX className="App-header-menu-icon" />
          </div>
        </div>
      )}
     <div className="App-header-link-container">
        <DropdownMenus
          parentItem = "Trade"
          small={small}
          menuItems = {[
            {
              label: "Trade",
              route: "/trade",
            },        
            {
              label: "Dashboard",
              route: "/dashboard",
            },
          ]}
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          activeSubMenu={activeSubMenu}
          onSubMenuChange={handleSubMenuChange}
        />
      </div>
      <div className="App-header-link-container">
        <DropdownMenus
          parentItem = "Swap"
          small={small}
          menuItems = {[
            {
              label: "Swaps V2",
              route: "/trade",
            },        
            {
              label: "Classic Swaps",
              route: "https://classic.omnidex.finance/swap",
            },
          ]}
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          activeSubMenu={activeSubMenu}
          onSubMenuChange={handleSubMenuChange}
        />
      </div>
      <div className="App-header-link-container">
        <DropdownMenus
          parentItem = "Lend"
          small={small}
          menuItems = {[
            {
              label: "Lending Markets",
              route: "https://lending.omnidex.finance/markets/",
            },        
            {
              label: "Lending Dashboard",
              route: "https://lending.omnidex.finance/dashboard",
            },
          ]} 
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          activeSubMenu={activeSubMenu}
          onSubMenuChange={handleSubMenuChange}
        />
        </div> 
      <div className="App-header-link-container">
        <DropdownMenus
          parentItem = "Earn"
          small={small}
          menuItems = {[
            {
              label: "OmniDex Staking",
              route: "/earn",
            },        
            {
              label: "Liquidity & Farming",
              route: "/buy",
            },
            {
              label: "TLOS Staking",
              route: "https://classic.omnidex.finance/tlospool",
            },
          ]}
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          activeSubMenu={activeSubMenu}
          onSubMenuChange={handleSubMenuChange}
        />
      </div> 
      <div className="App-header-link-container" >
        <ExternalLink href="https://classic.omnidex.finance/Portfolio">
          <Trans>Portfolio</Trans>
        </ExternalLink>
      </div>
      <div className="App-header-link-container" >
        <ExternalLink href="https://omnidex-stats.vercel.app/">
          <Trans>Stats</Trans>
        </ExternalLink>
      </div>
      <div className="App-header-link-container" >
        <DropdownMenus
          parentItem = "More"
          small={small}
          menuItems = {[
            {
              label: "Ecosystem",
              route: "/ecosystem",
            },
            {
              label: "Referrals",
              route: "/referrals",
            },
            {
              label: "OmnidexBP",
              route: "https://omnidexbp.com",
            },
            {
              label: "About",
              route: "https://docs.omnidex.finance/",
            },
          ]}
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          activeSubMenu={activeSubMenu}
          onSubMenuChange={handleSubMenuChange}
        />
        </div> 

    </div>
); 
}
