import React, { useRef, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { Menu } from "@headlessui/react";
import ModalWithPortal from "../Modal/ModalWithPortal";
import { t, Trans } from "@lingui/macro";
import cx from "classnames";
import { HiDotsVertical } from "react-icons/hi";
import "./OtherDropdown.css";
import "../Header/Header.css";


export default function DropdownMenus(props) {
  const [isVisible, setIsVisible] = useState(false)  
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const history = useHistory(); // Get the history object
  const parentItem = props.parentItem

  function navigateToUrlOrRoute(path) {
    const isUrl = /^(https?:\/\/|www\.)\S+$/.test(path);
  
    if (isUrl) {
      // If it's a URL, navigate to the specified URL
      window.location.href = path;
    } else {
      // If it's an internal React route, return the route path
      return path || null;
    }
  }

  function getModalContent() {
    return (
      <OtherModalContent
        setActiveModal={setIsVisible}
        otherOptions={props.menuItems}
        redirectPopupTimestamp={props.redirectPopupTimestamp}
        showRedirectModal={props.showRedirectModal}
      />
    );
  }
  
  function getModalProps() {
    return {
      className: "other-popup",
      isVisible: isVisible,
      setIsVisible: setIsVisible,
      label: parentItem,
    };
  }

  const handleClick = (event) => {
    event.stopPropagation(); // Prevent event propagation
    setIsVisible(true)
  };

  return (
    <>
      {props.small ? (
        <div className="App-header-other-small" onClick={handleClick}>
          <div className="other-dropdown">
            <NavIcons />
          </div>
        </div>
      ) : (
        <DesktopDropdown {...props} />
      )}
      <ModalWithPortal {...getModalProps()}>{getModalContent()}</ModalWithPortal>
    </>
  );


  function OtherModalContent({ otherOptions, setActiveModal,redirectPopupTimestamp,showRedirectModal }) {
    
    const handleItemClick = (route) => {
      setActiveModal(false);  
      navigateToUrlOrRoute(route);
    };
    
    return (
        <div className="other-dropdown-items">
          <div className="other-dropdown-list">          
            {otherOptions.map((options) => {
              return (
                <div
                  className="other-option"
                  onClick={() => handleItemClick(options.route )}
                >
                  <div className="other-menu-item-group">
                    <span>
                      <Link to={options.route} style={{ padding: '0.2rem', color:'white', fontSize: '1em', textDecoration: 'none', width: '100%', height: '100%' }}>{options.label}</Link>
                    </span>
                  </div>              
                </div>
              );
            })}
          </div>
        </div>
      );
    }

  function NavIcons() {
    return (
      <>
        <div>
          <span className="other-dropdown-item-label">{parentItem}</span>
        </div>
      </>
    );
  }
 
  

  function DesktopDropdown() {    
    const submenuRef = useRef<HTMLDivElement>(null);
  
    const handleShowSubMenu = () => {      
      console.log(" Is this the culprit 1") // JJ
      if (props.activeSubMenu !== props.parentItem) {        
        props.onSubMenuChange(parentItem);
      };
    }
  
    const handleOutsideClick = (event) => {
      console.log(" Is this the culprit 2") // JJ
      if (
        submenuRef.current &&
        !submenuRef.current.contains(event.target) &&
        !event.target.classList.contains('other-menu-item')
      ) {
        props.onSubMenuChange(null);
      }
    };


    const handleMenuItemClick = (route) => {    
      console.log(" Is this the culprit 3") // JJ  
      props.onSubMenuChange(null);
      navigateToUrlOrRoute(route)
    };
  
    useEffect(() => {
      document.addEventListener('click', handleOutsideClick);
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, []);
  
    return (
      <div
      className={`App-header-other ${props.activeSubMenu === props.parentItem ? "active" : ""}`}
      onMouseEnter={handleShowSubMenu}
      ref={submenuRef}
      >
        <Link to={props.menuItems[0].route} 
          onClick={() =>handleMenuItemClick(props.menuItems[0].route)}
          style={{ padding: '0.2rem', fontSize: '1em', width: '100%', height: '100%' }}
        >
          <div className="other-dropdown" style={{ paddingTop: '0.2rem'}}>{props.parentItem}</div>
        </Link>
        {props.activeSubMenu === props.parentItem && (
          <div className="hoverable-menu-container">
            {props.menuItems.map((item) => (
              <div key={item.label} className="other-dropdown-menu-item other-menu-item" onClick={() =>handleMenuItemClick(item.route)}>
                <div className="other-menu-item-group" >
                  <span className="other-dropdown-item-label" style={{ padding: '0.2rem', fontSize: '1em', width: '100%', height: '100%' }}>
                    <Link to={item.route} style={{ padding: '0.2rem', color:'white', fontSize: '1em', textDecoration: 'none', width: '100%', height: '100%' }}>{item.label}</Link>
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

}


